function videoModal() {
    //console.log("videoModal loaded!")
    const temp = `[data-whichVideoID]`;
    let items = document.querySelectorAll(temp);

    items.forEach( function(item){
        item.onclick = () => {
            console.log("click");
            const val = item.dataset.whichvideoid
            //console.log(item);
           // console.log(val);
            const find = `[data-myvideoid=${val}]`
            let elements = document.querySelectorAll(find);
            //console.log("elements")
           // console.log(elements)
            elements.forEach(function (element) {
                element.classList.add("active");

                console.log(element);
                console.log("clicker");
                const clicker = element.querySelector("#embed360close");
                console.log(clicker);
                element.querySelector(".close").onclick = () => {
                    const iframe = element.getElementsByTagName("iframe")[0];
                    const url = iframe.getAttribute('src');
                    iframe.setAttribute('src', '');
                    iframe.setAttribute('src', url);
                    element.classList.remove("active");
                }
            });
        }
    });


}

export default videoModal;