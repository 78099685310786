
function mobilesubnav()  {
    const headr = document.querySelector('header');
    const mobSubNavClose = document.querySelector('#closeMobileSubMenu');
    const subTitle = document.querySelector('#pane-sub-title');
    const subContent = document.querySelector('#pane-sub-content');
    const mobMainNav = document.querySelectorAll('.mobile-menu .menu-header-primary-menu-container > ul > li > a');
    mobMainNav.forEach(function (value) {
        if(value.nextElementSibling) {
            value.addEventListener('click', function(e) {
                e.preventDefault();
                subTitle.innerHTML = e.target.outerHTML;
                subContent.innerHTML = e.target.nextElementSibling.outerHTML;
                headr.classList.add('showMobileSubMenu');
            })
        }
    });
    mobSubNavClose.addEventListener('click', function(e) {
        headr.classList.remove("showMobileSubMenu");
    });
}
export default mobilesubnav;
