function product_package_tab () {
// Product Package URLs
    console.log("product_package_tab loaded");
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tab = urlParams.get('tab');
    if (tab) {
        let elements = document.querySelectorAll('.active')
        elements.forEach(function (element) {
            element.classList.remove("active")
        });
        const setActive = document.getElementById(tab);
        setActive.classList.add("active");

        const searchterm = `${tab}-tab`
        const setActiveTab = document.getElementById(searchterm);
        setActiveTab.classList.add("active");
    }

//Packagetabs
    const packagetabs = document.querySelectorAll("[data-packagetab]")
    packagetabs.forEach(function (packagetab) {
        packagetab.onclick = (mytab) => {
            const productpackage_menu = document.getElementById("product-package");
            let elements = productpackage_menu.querySelectorAll('.active');
            elements.forEach(function (element) {
                element.classList.remove("active")
            });
            console.log(`[data-package="${packagetab.dataset.packagetab}"]`);
            let moreElement = document.querySelectorAll("[data-package]");
            console.log(moreElement);
            moreElement.forEach(function (element) {
                element.classList.remove("active")
            });
            packagetab.classList.add("active");
            console.log(packagetab.dataset.packagetab);

            // Construct URLSearchParams object instance from current URL querystring.
            var queryParams = new URLSearchParams(window.location.search);
            // Set new or modify existing parameter value.
            queryParams.set("tab", packagetab.dataset.packagetab);
            // Replace current querystring with the new one.
            history.replaceState(null, null, "?" + queryParams.toString());


            const test = document.getElementById(`${packagetab.dataset.packagetab}`)
            test.classList.add("active");
            // test.classList.add("active");
        }
    });


    if (document.querySelector('[data-supportlist]')) {
        document.querySelector('[data-supportlist]').classList.add("selected");
        const el = `[data-support="${document.querySelector('[data-supportlist]').dataset.supportlist}" ]`;
        document.querySelector(el).classList.add("selected");
        console.log()
    }
}
export default product_package_tab;
