function mainmenu() {
    //console.log("videoModal loaded!")
    const body = document.querySelector('body');
    const headr = document.querySelector('header');
    const openSearch = document.querySelector("#openSearch");
    const closeSearch = document.querySelector("#closeSearch");
    const openMenu = document.querySelector("#openMobileMenu");
    const closeMenu = document.querySelector("#closeMobileMenu");

    openSearch.addEventListener('click', function(e) {
        e.preventDefault();
        headr.classList.add("showSearch");
    });

    closeSearch.addEventListener('click', function(e) {
        e.preventDefault();
        headr.classList.remove("showSearch");
    });

    openMenu.addEventListener('click', function(e) {
        e.preventDefault();
        body.classList.add("body-lock");
        headr.classList.add("showMobileMenu");
    });

    closeMenu.addEventListener('click', function(e) {
        e.preventDefault();
        body.classList.remove("body-lock");
        headr.classList.remove("showMobileMenu","showMobileSubMenu");
    });
}

export default mainmenu;