function filterbar() {
    console.log("filterbar loaded!")
    const filterbartarget = document.getElementById("filter-bar-search");
    if (filterbartarget) {
        queryURL("product_package-categories");
        queryURL("category_name");
        queryURL("tag");
        filterbartarget.onclick = () => {
            myClickBehabvior();
        }
        const clickables = document.querySelectorAll(".filter-bar label")
        clickables.forEach( function(clickable) {
            clickable.onclick = () => {
                myClickBehabvior();
            }
        });
    }
}

function myClickBehabvior() {
    const datasets = document.getElementById("filter-bar");
    let ourSelected = [];
    let ourSelectedTags = [];
    let ourString = "";
    const searchValue = document.getElementById("search-field");
    const delayInMilliseconds = 20; //1 second

    setTimeout(function() {
        //your code to be executed after 1 second
        const selecteds = document.querySelectorAll("#filter-bar input:checked");
        const selectedtags =  document.querySelectorAll("#ourtags input:checked");

        selecteds.forEach( function (selected) {
            ourSelected.push(selected.value);
        });
        selectedtags.forEach( function (selectedtag) {
            console.log(selectedtag.value);
            ourSelectedTags.push(selectedtag.value);
        });
        ourSelected.forEach( function(item, index) {
            if (index === 0 ) {
                ourString = `${ourString}${item}`;
            } else {
                ourString = `${ourString}+${item}`;
            }
        });
        ourSelectedTags.forEach( function(item, index) {
            console.log(index)
            if (index === 0 ) {
                ourString = `${ourString}&tag=${item}`;
            } else {
                ourString = `${ourString}+${item}`;
            }
        });
        let http = "https://";
        console.log(window.location.hostname);
        if (window.location.hostname === "localhost")  {
            http = "http://";
        }
        ourString = `${http}${window.location.host}?post_type=${datasets.dataset.posttype}&s=${searchValue.value}&${datasets.dataset.taxonomy}=${ourString}`;
        if (datasets.dataset.posttype === "post") {
            //ourString = `${ourString}&taxonomy=category`
        }
         window.location.href = ourString;
        console.log(ourString);
    }, delayInMilliseconds);
}

function queryURL (myProperty) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const product = urlParams.get(myProperty);

    if (product) {
        const myArray = product.split(" ");
        myArray.forEach( function(urlParam) {
            console.log(urlParam)
            const myquery = `[value="${urlParam}"]`
            const checkme = document.querySelector(myquery);
            checkme.checked = true;
        });
    }
}

export default filterbar;