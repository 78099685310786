
function submenu(menuitem, mytarget, mygroup)  {
    const temp = `[data-${menuitem}]`;
    let items = document.querySelectorAll(temp);
    items.forEach( function(item){
        item.onclick = () => {
            //console.log("mytarget");
            //console.log(mytarget);

            const val = item.dataset.[menuitem]
            const area = document.querySelector(mygroup);

            let elements = area.querySelectorAll('.selected');
            elements.forEach(function (element) {
                element.classList.remove("selected");
            });
            const target1 = `[data-${menuitem}="${val}"]`;
            const target2 = `[data-${mytarget}="${val}"]`;
            //console.log(target1);
            let myTarget1 = document.querySelector(target1);
            //console.log(myTarget1);
            let myTarget2 = document.querySelector(target2);
            //console.log(target2);
            //console.log(myTarget2);

            myTarget1.classList.add("selected");
            myTarget2.classList.add("selected");
        }
    });
}
export default submenu;
