//console.log("hello world");
// responsive youtube
import reframe from "./libs/reframe";
reframe(document.querySelectorAll('iframe[src*="youtube"]'));
//slide show
import Swiper from 'swiper/bundle';

//
// Product Package specific
//
import submenu from './custom/submenu';
submenu("supportlist","support", "#support");
submenu("speclist", "specs", "#specifications");
import product_package_tab from "./custom/product-package-tab";
product_package_tab();
import embed360 from './custom/360modal';
embed360();
import videomodal from './custom/videomodal';
videomodal();
import filterbar from "./custom/filterbar";
filterbar();
import specialcharacters from "./custom/specialcharacters"
specialcharacters();
import mobileSubNav from "./custom/mobilesubnav";
mobileSubNav();
import mainmenu from "./custom/mainmenu";
mainmenu();
import interactivemap from "./custom/interactivemap";
interactivemap();
//import searchClick from "./custom/searchclick";
//searchClick();

// libs
import Cookies from 'js-cookie';
import MatchHeight from '@tannerhodges/match-height';

//
// end product package
//

// show more
const showmores = document.querySelectorAll(".show-more")
showmores.forEach( function(showmore) {
    showmore.onclick = () => {
        showmore.parentElement.classList.add("show");
        showmore.parentElement.classList.add("show-less-button");
    }
});

const showlesses = document.querySelectorAll(".show-less")
showlesses.forEach( function(showless) {
    showless.onclick = () => {
        showless.parentElement.classList.remove("show");
        setTimeout(() => { showless.parentElement.classList.remove("show-less-button"); }, 800);
    }
});

// Swiper
const element = document.createElement("div");
element.classList.add('swiper-pagination');;

const elements = document.querySelectorAll('.wp-block-mineralblocks-quote-carousel');
elements.forEach( function(me) {
    me.appendChild(element)
});

const genericSwiper = document.querySelectorAll('.swiper-container .wp-block-mineralblocks-row');
genericSwiper.forEach( function(me) {
    me.classList.add('swiper-slide');
});



// swiper-pagination
//https://medium.com/@networkaaron/swiper-how-to-destroy-swiper-on-min-width-breakpoints-a947491ddec8

const swiper = new Swiper('.swiper-container', {
    speed: 400,
    spaceBetween: 0,
    autoHeight: true, //enable auto height
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    },
    autoplay: {
        delay: 6000,
    },
    breakpoints: {

        // when window width is <= 320px
        320: {
            slidesPerView: 1,
            spaceBetween: 15,
            centeredSlides: true,
            //loop: true,
            slidesPerGroup: 1,
        },
        // when window width is <= 640px
        768: {
            slidesPerView: 1,
            spaceBetween: 0
        }

    }
});
const swiper2 = new Swiper('.swiper-container-next', {
    speed: 400,
    spaceBetween: 0,
    autoHeight: true, //enable auto height
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    autoplay: {
        delay: 6000,
    },
    breakpoints: {

        // when window width is <= 320px
        320: {
            slidesPerView: 1,
            spaceBetween: 15,
            centeredSlides: true,
            //loop: true,
            slidesPerGroup: 1,
        },
        // when window width is <= 640px
        768: {
            slidesPerView: 1,
            spaceBetween: 0
        }
    }
});

function createClickableSlideShow(input) {
    input.forEach( function(me) {
        //if make sure there's a default active.
        const isActive = me.querySelectorAll('.active')
        if (isActive.HTMLCollection === undefined) {
            me.querySelector(".slide0").classList.add("active");
            me.querySelector('[data-slidecontent="0"]').classList.add("active");
        };
        //Add the click functionality for add/remove active states
        me.querySelectorAll(".bars > div").forEach(
            function(clicker){
                clicker.addEventListener('click', function() {
                    if (me.querySelector('.bars .active') !== null ) {
                        me.querySelector('.bars .active').classList.remove("active")
                    }
                    if (me.querySelector('.content .active') !== null) {
                        me.querySelector('.content .active').classList.remove("active")
                    }
                    if (me.querySelector(`.slide${clicker.dataset.slides}`) !== null) {
                        me.querySelector(`.slide${clicker.dataset.slides}`).classList.add("active");
                    }
                    if (me.querySelector(`[data-slidecontent="${clicker.dataset.slides}"]`) !== null) {
                        me.querySelector(`[data-slidecontent="${clicker.dataset.slides}"]`).classList.add("active");
                    }
                }.bind(this));
            }
        );
    });
}
//stacked controls
if (document.querySelectorAll('.wp-block-mineralblocks-stacked-control-group')){
    createClickableSlideShow(document.querySelectorAll('.wp-block-mineralblocks-stacked-control-group'));
}

// storytelling media
if (document.querySelectorAll('.wp-block-mineralblocks-storytelling-media-group')) {
    createClickableSlideShow(document.querySelectorAll('.wp-block-mineralblocks-storytelling-media-group'));

}

//set autoplay
if(swiper) {
    console.log("swiper");
    console.log(swiper);
    if (swiper.autoplay) {
        swiper.autoplay.stop();
    }
    if (swiper.el !== undefined) {
        if (swiper.el.hasOwnProperty('classList')) {
            if (swiper.el.classList.contains("autoplay")) {
                swiper.autoplay.start();
            }
        }
    }
}
if(swiper2) {
    console.log("swiper2");
    console.log(swiper2);
    if (swiper2.autoplay) {
        swiper2.autoplay.stop();
    }
    if (swiper2.el !== undefined) {
        if (swiper2.el.hasOwnProperty('classList')) {
            if (swiper2.el.classList.contains("autoplay")) {
                swiper2.autoplay.start();
            }
        }
    }
}

const disabledsingles = document.querySelectorAll(".swiper-container");
disabledsingles.forEach( function(me) {
    // console.log(me);
    // console.log(me.querySelectorAll(".swiper-slide").length);
    if (me.querySelectorAll(".swiper-slide").length == 1 ) {
        me.querySelector(".swiper-wrapper").classList.add("disabled");
       //console.log(me.parentNode);
        me.parentNode.classList.add("disabled");
    }
});
const disabledsingles2 = document.querySelectorAll(".swiper-container-initialized");
disabledsingles2.forEach( function(me) {
    // console.log(me);
    // console.log(me.querySelectorAll(".swiper-slide").length);
    if (me.querySelectorAll(".swiper-slide").length == 1 ) {
        me.querySelector(".swiper-wrapper").classList.add("disabled");
        //console.log(me.parentNode);
        me.parentNode.classList.add("disabled");
    }
});


// match height
(function matchHeight(){
    //Grab divs with the class name 'match-height'
    const getDivs = document.getElementsByClassName('match-height');
    //Find out how my divs there are with the class 'match-height'
    const arrayLength = getDivs.length;
    let heights = [];
    //Create a loop that iterates through the getDivs variable and pushes the heights of the divs into an empty array
    for (var i = 0; i < arrayLength; i++) {
        heights.push(getDivs[i].offsetHeight);
    }
    //Find the largest of the divs
    function getHighest() {
        return Math.max(...heights);
    }
    //Set a variable equal to the tallest div
    const tallest = getHighest();
    //Iterate through getDivs and set all their height style equal to the tallest variable
    for (var i = 0; i < getDivs.length; i++) {
        getDivs[i].style.height = tallest + "px";
    }
})();

const filterBar = document.querySelector(`.filter-bar form`);
if (filterBar) {
    filterBar.querySelectorAll("#thecategory").forEach(function (me){
        me.addEventListener('change', function () {
           // filterBar.submit();
        });
    });
}
