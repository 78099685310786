function interactivemap () {
    const interactive = document.querySelectorAll(".plus");
    const isThere = document.getElementById("interactive-content");
    if (isThere) {
        document.querySelector(".plus.one").classList.add("active");
        document.querySelector(".mycontent.one").classList.add("active");
        interactive.forEach( function(item, index) {
            item.addEventListener( 'click', function() {
                const myid = item.dataset.myid;
                const target = `.plus:not([data-myid="${myid}"])`;
                const removeActives1 = document.querySelectorAll(target);
                removeActives1.forEach( function(removeActive) {
                    removeActive.classList.remove("active")
                });
                const target2 = `[data-mytarget]:not([data-mytarget="${myid}"])`;
                const removeActives2 = document.querySelectorAll(target2);
                removeActives2.forEach( function(removeActive) {
                    removeActive.classList.remove("active")
                });
                item.classList.toggle("active");
                const target3 = `[data-mytarget="${myid}"]`;
                console.log("test");
                const myActives = document.querySelectorAll(target3);
                myActives.forEach( function(myActive) {
                    myActive.classList.toggle("active");
                });
            });
        });
    }



}
export default interactivemap;
