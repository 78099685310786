function embed360() {
    //console.log("embed360 loaded!")
    // Clickable 360
    // https://test-lightspeed-tek.pantheonsite.io/?post_type=product_package&s=flexmike&product_package-categories=microphones

    const embed360target = document.getElementById("embed360");
    console.log(embed360target);
    if (embed360target) {

        console.log("embed360 found");
        embed360target.onclick = () => {
            document.getElementById("embed360modal").classList.add("active");
        }
        const embed360close = document.getElementById("embed360close" );
        console.log("embed360close");
        console.log(embed360close);
        embed360close.onclick = () => {
            document.getElementById("embed360modal").classList.remove("active");
        }
    }
}

export default embed360;