function specialcharacters() {
    const chars = document.querySelectorAll(".special-characters-filter");

    //https://stackoverflow.com/questions/8207066/creating-array-of-regular-expressions-javascript
    function giveMeDOM(html) {

        let div = document.createElement('div'),
            frag = document.createDocumentFragment();

        div.innerHTML = html;

        while (div.firstChild) {
            frag.appendChild( div.firstChild );
        }

        return frag;
    }

    const specialslist = [
        {
            regex: /™/g,
            string: "<span class='special-character__super'>™</span>"
        },
        {
            regex: /®/g,
            string: "<span class='special-character__sub'>®</span>"
        },
        {
            regex: /©/g,
            string: "<span class='special-character__sub'>®</span>"
        }
    ];
    // const reg = new RegExp(specialslist.join("|"), "i");
    if (chars) {
        specialslist.forEach(function (me) {

            const searcher = document.querySelectorAll(".special-characters-filter")


            searcher.forEach(function (item) {
                const firstChild = item.firstChild;
                // Merge adjacent text nodes:
                item.normalize();
                // Get new DOM structure:
                const newStructure = giveMeDOM(firstChild.nodeValue.replace(me.regex, me.string));
                // Replace first child with new DOM structure:
                item.replaceChild(newStructure, firstChild);
            });
            //replaceOnDocument(me.regex, me.string, ".special-characters-filter");
        });

    }
}

export default specialcharacters;